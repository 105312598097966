export default [
  {
    header: 'Aplicação',
  },
  {
    title: 'Páginas',
    route: 'apps-pages-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Bairros',
    route: 'apps-neighborhoods-list',
    icon: 'MapIcon',
  },
  {
    title: 'Anúncios',
    route: 'apps-financial-list',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Usuários',
    icon: 'UserIcon',
    children: [
      {
        title: 'Pendentes',
        route: 'apps-users-list-in-progress',
      },
      {
        title: 'Todos',
        route: 'apps-users-list',
      },
      /* {
        title: 'Editar',
        route: { name: 'apps-users-edit', params: { id: 21 } },
      }, */
    ],
  },
]
